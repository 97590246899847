var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0 pt-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" "+_vm._s(_vm.$t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TITLE"))+" ")]),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(_vm._s(_vm.$t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.SUB_TITLE")))])])]),_c('div',{staticClass:"card-body pt-2 pb-0"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-borderless table-vertical-center"},[_c('thead',[_c('tr',[_c('th',{staticClass:"p-0",staticStyle:{"min-width":"120px"}},[_vm._v(" "+_vm._s(_vm.$t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.USER_TITLE"))+" ")]),_c('th',{staticClass:"p-0",staticStyle:{"min-width":"120px"}},[_vm._v(" "+_vm._s(_vm.$t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.EVENT_TITLE"))+" ")]),_c('th',{staticClass:"p-0",staticStyle:{"min-width":"70px"}},[_vm._v(" "+_vm._s(_vm.$t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.EVENT_DATE"))+" ")]),_c('th',{staticClass:"p-0",staticStyle:{"min-width":"70px"}},[_vm._v(" "+_vm._s(_vm.$t( "PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.RESERVATION_DATE" ))+" ")]),_c('th',{staticClass:"p-0",staticStyle:{"min-width":"50px"}},[_vm._v(" "+_vm._s(_vm.$t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.DOSE"))+" ")]),_c('th',{staticClass:"p-0",staticStyle:{"min-width":"50px"}},[_vm._v(" "+_vm._s(_vm.$t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.STATUS"))+" ")])])]),_c('tbody',[_vm._l((_vm.reservationsList.data),function(item,i){return [_c('tr',{key:i},[_c('td',{staticClass:"pl-0"},[_c('router-link',{attrs:{"to":{
                    name: 'citizens_form',
                    params: { id: item.citizen.id },
                  },"target":"_blank"}},[_c('span',{staticClass:"text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"},[_vm._v(" "+_vm._s(item.citizen.name)+" "+_vm._s(item.citizen.surname)+" ")]),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v(" "+_vm._s(item.citizen.fiscal_code)+" - "+_vm._s(item.citizen.city_of_residence.denomination)+" ")])])],1),_c('td',{staticClass:"pl-0"},[_c('router-link',{attrs:{"to":{
                    name: 'events_form',
                    params: { id: item.event.id },
                  },"target":"_blank"}},[_c('span',{staticClass:"text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"},[_vm._v(" "+_vm._s(item.event.title)+" - "+_vm._s(item.event.city.denomination)+" ")]),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v(" "+_vm._s(item.event.title)+" - "+_vm._s(item.event.city.denomination)+" ")])])],1),_c('td',{staticClass:"p-0 text-left"},[_c('span',{staticClass:"text-muted font-weight-bold d-block font-size-sm"},[_vm._v(" "+_vm._s(new Date(item.reservation_date_time).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", }))+" ")])]),_c('td',{staticClass:"p-0 text-left"},[_c('span',{staticClass:"text-muted font-weight-bold d-block font-size-sm"},[_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", })))])]),_c('td',{staticClass:"p-0 text-left"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(item.dose.title))])]),_c('td',{staticClass:"p-0 text-left"},[_c('span',[(item.status == true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)]),_c('td',{staticClass:"text-right pr-0"},[_c('router-link',{attrs:{"to":{
                    name: 'reservations_overview',
                    params: { id: item.id },
                  },"target":"_blank"}},[_c('div',{staticClass:"btn btn-icon btn-light w-auto btn-clean d-inline-flex align-items-center btn-lg px-2 mr-5"},[_c('span',{staticClass:"symbol symbol-35 symbol-light-primary"},[_c('span',{staticClass:"symbol-label font-size-h5 font-weight-bold"},[_c('inline-svg',{staticClass:"white",attrs:{"src":"/media/svg/icons/Navigation/Arrow-right.svg"}})],1)])])])],1)])]})],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }