<template>
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{ $t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TITLE") }}
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
          $t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.SUB_TITLE")
        }}</span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="min-width: 120px">
                {{ $t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.USER_TITLE") }}
              </th>
              <th class="p-0" style="min-width: 120px">
                {{ $t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.EVENT_TITLE") }}
              </th>
              <th class="p-0" style="min-width: 70px">
                {{ $t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.EVENT_DATE") }}
              </th>
              <th class="p-0" style="min-width: 70px">
                {{
                  $t(
                    "PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.RESERVATION_DATE"
                  )
                }}
              </th>
              <th class="p-0" style="min-width: 50px">
                {{ $t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.DOSE") }}
              </th>
              <th class="p-0" style="min-width: 50px">
                {{ $t("PAGES.DASHBOARD.WIDGET.RESERVATIONS.TABLE.STATUS") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in reservationsList.data">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <router-link
                    :to="{
                      name: 'citizens_form',
                      params: { id: item.citizen.id },
                    }"
                    target="_blank"
                  >
                    <span
                      class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >
                      {{ item.citizen.name }} {{ item.citizen.surname }}
                    </span>
                    <span class="text-muted font-weight-bold d-block">
                      {{ item.citizen.fiscal_code }} -
                      {{ item.citizen.city_of_residence.denomination }}
                    </span>
                  </router-link>
                </td>
                <td class="pl-0">
                  <router-link
                    :to="{
                      name: 'events_form',
                      params: { id: item.event.id },
                    }"
                    target="_blank"
                  >
                    <span
                      class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >
                      {{ item.event.title }} - {{ item.event.city.denomination }}
                    </span>
                    <span class="text-muted font-weight-bold d-block">
                      {{ item.event.title }} -
                      {{ item.event.city.denomination }}
                    </span>
                  </router-link>
                </td>
                <td class="p-0 text-left">
                  <span
                    class="text-muted font-weight-bold d-block font-size-sm"
                  >
                    {{
                      new Date(item.reservation_date_time).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })
                    }}
                  </span>
                </td>
                <td class="p-0 text-left">
                  <span
                    class="text-muted font-weight-bold d-block font-size-sm"
                  >
                    {{
                      new Date(item.createdAt).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })
                    }}</span
                  >
                </td>
                <td class="p-0 text-left">
                  <span class="font-weight-bolder">{{ item.dose.title }}</span>
                </td>
                <td class="p-0 text-left">
                  <span>
                    <v-icon color="green" v-if="item.status == true"
                    >mdi-check-circle</v-icon
                    >
                    <v-icon color="red" v-else>mdi-minus-circle</v-icon>
                </span>
                </td>
                <td class="text-right pr-0">
                  <router-link
                    :to="{
                      name: 'reservations_overview',
                      params: { id: item.id },
                    }"
                    target="_blank"
                  >
                    <div
                      class="btn btn-icon btn-light w-auto btn-clean d-inline-flex align-items-center btn-lg px-2 mr-5"
                    >
                      <span class="symbol symbol-35 symbol-light-primary">
                        <span class="symbol-label font-size-h5 font-weight-bold"
                          ><inline-svg
                            class="white"
                            src="/media/svg/icons/Navigation/Arrow-right.svg"
                        /></span>
                      </span>
                    </div>
                  </router-link>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import {FETCH_RESERVATIONS_LIST} from "@/core/services/store/reservations.module";

import {mapGetters} from "vuex";

export default {
  name: "widget-9",
  data() {
    return {
      filters: {
        query: [],
        filters: {},
        sort: [
          {
            direction: "DESC",
            field: "id",
          },
        ],
        page: 1,
        limit: 30,
      },
    };
  },
  methods: {
    goToOverview(dataID) {
      let routeData = this.$router.resolve({
        name: "reservations_overview",
        query: { id: dataID },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push('/#/reservation/overview/' + id).catch(()=>{});
    },
    goToCitizen(id) {
      this.$router.push("/citizen/edit/" + id);
    },
  },
  computed: {
    ...mapGetters(["reservationsList"]),
    dataToShow() {
      if (this.show === "month") return this.month;
      if (this.show === "week") return this.week;
      if (this.show === "day") return this.day;
      return this.day;
    },
  },
  mounted() {
    this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.filters);
  },
};
</script>
